import * as TYPES from './type'

export default {
  [TYPES.SET_LANG]: (state, payload) => {
    state.lang = payload
  },
  [TYPES.SET_PROFILE]: (state, payload) => {
    state.profile = payload
  },
}
